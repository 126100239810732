<template>
  <v-container fluid>
    <v-card class="mb-3 py-0" v-if="filter">
      <v-card-actions class="py-0 px-5">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field v-model="search" prepend-icon="fa-search" label="Meklēt" single-line hide-details clearable/>
          </v-col>
          <v-col cols="12" md="2">
            <v-select label="Status" prepend-icon="fa-toggle-on" v-model="status" :items="statuses" item-text="text" item-value="value" clearable/>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>Uzdevumi</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon :to="{name: 'taskCreate'}" color="primary">
          <v-icon>fa-plus</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="filter = !filter">
          <v-icon>fa-sliders-h</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="tasks"
        item-key="item.id"
        :loading="loading"
        loading-text="Notiek ielāde... Lūdzu, uzgaidiet"
        :search="search">
        <template v-slot:item.status="{ item }">
          <v-chip small :color="statusColor(item.status)" v-html="statusText(item.status)"></v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="primary" :to="{name: 'taskShow', params: {id: item.id}}">
            <v-icon>fa-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/axios'

export default {
  name: 'TaskIndex',
  data: () => ({
    filter: false,
    loading: true,
    search: '',
    user: null,
    status: null,
    tasks: []
  }),
  computed: {
    statuses () {
      return this.$store.state.statuses
    },
    headers () {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Date Start',
          value: 'date_start'
        },
        {
          text: 'Date End',
          value: 'date_end'
        },
        {
          text: 'Status',
          value: 'status',
          filter: value => {
            if (this.status === 0 || this.status === 1) {
              return value === this.status
            }
            if (!this.status) return true
          }
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    getTasks () {
      axios.get('tasks').then(response => {
        this.tasks = response.data
        this.loading = false
      })
    }
  },
  created () {
    this.getTasks()
  }
}
</script>

<style scoped>

</style>
